import { useLocation } from "@reach/router"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { loadStripe } from "@stripe/stripe-js"
import Loader from "components/UI/Loader"
import SEO from "components/SEO"
import { Button } from "nzk-react-components"
import { CheckoutLayout } from "../../../layouts/CheckoutLayout"
import config from "../../../../config"

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
`

const FullscreenLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #00000033;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormError = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #ec5e2e;
  color: #fff;
  padding: 8px 10px;
  border-radius: 6px;
  text-align: center;
`

const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 20px 10px 20px;
  .informations {
    margin-top: 10px;
  }

  .details-form {
    margin-top: 20px;
  }

  > * {
    margin-bottom: 10px;
  }
  a {
    color: #701ea8;
    text-decoration: underline;
  }
  a.redirect-link {
    font-size: 0.8em;
    margin-top: 20px;
  }
  border-radius: 12px;
  border: 3px solid #701ea8;
  max-width: 400px;
  h1 {
    font-family: "Rammetto One", cursive;
    font-size: 1.8em;
    color: #45106d;
    margin-bottom: 20px;
  }
  text-align: center;
`

interface IProps {
  setupIntent: string | null
  paymentIntent: string | null
}

const ParentCheckoutRetry = (props: IProps) => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState<string | null>(null)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async () => {
    if (loading || !elements || !stripe) return

    setLoading(true)
    const { error: submitError } = await elements.submit()

    if (submitError) {
      setLoading(false)
      return
    }

    if (props.paymentIntent) {
      const result = await stripe?.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${location.origin}/parents/checkout/complete`,
        },
      })
      if (result.error) {
        setFormError(result.error.message || null)
      }
      setLoading(false)
    } else if (props.setupIntent) {
      const result = await stripe?.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${location.origin}/parents/checkout/complete`,
        },
      })
      if (result.error) {
        setFormError(result.error.message || null)
      }
      setLoading(false)
    }
  }

  return (
    <Content>
      {loading && (
        <FullscreenLoader>
          <Loader size={40} color="#45106b" />
        </FullscreenLoader>
      )}
      <h1>Your details</h1>
      <p className="informations">
        Please add or update your payment details to finish setting up your free
        trial.
      </p>
      <div className="details-form">
        <PaymentElement />
        {formError && <FormError>{formError}</FormError>}
        <div>
          <Button theme="purple" size="small" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </div>
      </div>
      <p>
        If the problem persists, please contact{" "}
        <a href="mailto:support@nightzookeeper.com">
          support@nightzookeeper.com
        </a>
      </p>
    </Content>
  )
}

export default () => {
  const stripePromise = useMemo(() => loadStripe(config.stripeKey), [])
  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const clientSecret =
    params.get("payment_intent_client_secret") ||
    params.get("setup_intent_client_secret")

  return (
    <CheckoutLayout>
      <>
        <SEO
          title="Checkout Retry"
          titleTemplate="%s | Night Zookeeper"
          noIndex
        />
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: clientSecret || undefined,
          }}
        >
          <Wrapper>
            <ParentCheckoutRetry
              setupIntent={params.get("setup_intent")}
              paymentIntent={params.get("paymentIntent")}
            />
          </Wrapper>
        </Elements>
      </>
    </CheckoutLayout>
  )
}
